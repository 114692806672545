import React from 'react';
import { useHistory } from 'react-router';
import { ButtonLink } from '@naf/button-link';
import { Text, TextVariant } from '@naf/text';
import { GridCol } from '@naf/grid';
import * as S from './Styles';
import BenefitsList from '../../components/benefits-list/BenefitsList';
import StA from '../../styles/articles/StyledArticle';

type RelatedBenefitsProps = {
  relatedContent: [];
};

const RelatedBenefits = ({ relatedContent }: RelatedBenefitsProps) => {
  const history = useHistory();

  return (
    <S.StyledGridRow>
      <GridCol s="12" m="12" l="12" xl="12">
        <StA.ReadMoreHRLine />
        <S.ReadMoreWrapper>
          <Text tag="h2" variant={TextVariant.Header3}>
            Relevante fordeler
          </Text>
          <ButtonLink
            text="Se alle medlemsfordeler"
            variant="secondary"
            onClick={() => history.push('/medlemskap/medlemsfordeler')}
          />
        </S.ReadMoreWrapper>
        <BenefitsList benefitItems={relatedContent} />
      </GridCol>
    </S.StyledGridRow>
  );
};

export default RelatedBenefits;
